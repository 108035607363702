<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" width="90%" :before-close="handleClose">
    <el-form :model="dataFrom" label-width="100px" ref="dataFrom">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="名称" width="120px" prop="name">
            <el-input width="20px" v-model="dataFrom.name" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="级别" width="120px" prop="grade">
            <el-select v-model="dataFrom.grade" filterable placeholder="请选择" style="width:90%">
              <el-option
                v-for="item in gradeList"
                :key="item.key"
                :label="item.value"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="标签" prop="labels">
            <el-tag
              :key="tag"
              v-for="tag in dynamicTags"
              closable
              :disable-transitions="false"
              @close="handleCloseTag(tag)"
            >{{tag}}</el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="dataFrom.labels"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            ></el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="链接" width="120px" prop="buyUrl">
            <el-input width="20px" v-model="dataFrom.buyUrl" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="店铺" width="120px" prop="buyShop">
            <el-input width="20px" v-model="dataFrom.buyShop" />
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="地址" width="120px" prop="address">
            <el-input width="20px" v-model="dataFrom.address" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="加入计划" prop="isPlan">
            <el-switch
              v-model="dataFrom.isPlan"
              active-color="#ff4949"
              inactive-color="#13ce66"
              active-text="否"
              inactive-text="是"
              :active-value="2"
              :inactive-value="1"
            ></el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="加入收藏" prop="isCollect">
            <el-switch
              v-model="dataFrom.isCollect"
              active-color="#ff4949"
              inactive-color="#13ce66"
              active-text="否"
              inactive-text="是"
              :active-value="2"
              :inactive-value="1"
            ></el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="this.dataFrom.isPlan==1">
          <el-form-item label="计划时间" width="120px" prop="planData">
            <el-date-picker
              v-model="dataFrom.planData"
              type="month"
              value-format="yyyy-MM"
              placeholder="选择月"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="商品信息" width="120px" prop="shopInfo">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="dataFrom.shopInfo"
              show-word-limit
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel()">取 消</el-button>
      <el-button type="primary" @click="editSaveButton">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { selectlifeshopByIdApi } from "@/api/life/shop/lifeshop";

export default {
  name: "saveEdit",
  data() {
    return {
      gradeList: {},
      dialogVisible: false,
      dataFrom: {},
      dataFromRef: {},
      ids: null,
      types: 1,
      editShow: true,
      title: null,
      dynamicTags: [],
      inputVisible: false
    };
  },
  methods: {
    //打开弹窗 1新增 2编辑
    showFund(id, type) {
      this.initEnumList();
      this.ids = id;
      this.dialogVisible = true;
      this.types = type;
      if (type == 2) {
        this.selectDataById();
      }
    },
    selectDataById() {},
    cancel() {
      this.dataFrom = {};
      this.dataFromRef = {};
      this.dialogVisible = false;
      //调用父组件的查询
      this.$parent.queryDataList();
      this.ids = null;
      this.$parent.id = null;
      this.$parent.questionnaireId = null;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          this.cancel();
        })
        .catch(_ => {});
    },
    editSaveButton() {},
    initEnumList() {},
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.dataFrom.labels;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.dataFrom.labels = "";
    }
  }
};
</script>
<style lang="less" scoped>
</style> 